import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import HoverChat from "./HoverChat";
import HoverVideo from "./HoverVideo";
import styled from "styled-components";
import firebase from "./firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import { Portal } from "react-portal";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size/throttled";
import ntb from "./not-the-billionaires.svg";

const desktop = 1024;
const tablet = 768;

const tabletQuery = `(orientation: portrait) and (min-width: ${tablet}px) and (max-width: ${
  desktop - 1
}px)`;
const desktopQuery = `(min-width: ${desktop}px)`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  // height: 100%;
  flex: 1 1 auto;

  @media ${desktopQuery} {
    flex-direction: row;
  }

  @media ${tabletQuery} {
    flex-direction: row;
    width: 100%;
  }
`;

const Button = styled.div`
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  padding: 4px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
`;

const Nav = styled.div`
  display: flex;
  flex: 0 0 auto;
  background: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 9999;

  @media ${desktopQuery} {
    display: none;
  }

  @media ${tabletQuery} {
    display: none;
  }
`;

const NavButton = styled.div`
  display: block;
  width: 100%;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;

  span {
    padding: 4px 8px;
    color: #f51e47;
    // background: ${({ active }) => (active ? "#fff" : "#2b9cd9")};
    // color: ${({ active }) => (active ? "#2b9cd9" : "#fff")};
    border-radius: 6px;
  }
  border-left: 1px solid #eee;
  border-bottom: 3px solid ${({ active }) => (active ? "#f51e47" : "#fff")};
`;

const DonateButton = styled.a`
  background: #f51e47;
  color: rgb(255, 255, 255);
  font-weight: bold;
  display: block;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin: 8px;
  cursor: pointer;
`;

const OuterFrame = styled.div`
  display: flex;
  width: 100%;
  background: #f5f6f8;
  background-image: url(/crowd-bg.png);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  flex-direction: column;

  // &:before {
  //   position: absolute;
  //   top: 50%;
  //   width: 100%;
  //   left: 0;
  //   z-index: -1;
  //   height: 400px;
  //   content: '';
  //   transform: translateY(-50%);
  // }
  height: 100vh;
  overflow: hidden;

  @media ${tabletQuery} {
    // display: block;
  }

  @media ${desktopQuery} {
    height: 100vh;
    padding: 0;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  display: block;
`;

const Toggle = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  height: 20px;
  width: 20px;
  color: white;
  z-index: 10000;
  font-size: 20px;
  padding: 8px;
  background: #f51e47;
  cursor: pointer;
  border-radius: 8px 0 0 8px;
  transition: padding-right ease-in 0.2s;

  &:hover {
    // box-shadow: 0 0 4px rgba(0, 0, 0, .5);
    padding-right: 16px;
  }
`;

const Header = styled.div`
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
  position: relative;
  width: 100%;
  background: white;
  height: 44px;
  top: 0;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: space-between;

  @media (${tabletQuery}) {
    position: relative;
    height: 59px;
  }

  @media ${desktopQuery} {
    position: relative;
    height: 59px;
  }
`;

const Banner = styled.div`
  background: #2b9cd9;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  font-size: 13px;
  text-align: center;
  clear: both;
  flex: 0 0 auto;

  p {
    margin: 0;
  }
  a {
    color: white;
  }

  @media ${desktopQuery} {
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
  }
`;
const Stage = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: auto;

  @media (max-width: ${desktop - 1}px) and (orientation: landscape) {
    flex-direction: row;
  }

  @media ${desktopQuery} {
    flex-direction: row;
  }

  @media ${tabletQuery} {
    // display: block;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const DonationFrame = styled.div`
  // background: #eee;
  text-align: center;
  min-height: 200px;
  display: ${({ active }) => (active ? "block" : "none")};
  position: relative;
  flex: 1 1 auto;
  max-height: 100%;
  min-width: 320px;

  @media ${desktopQuery} {
    width: 320px;
    order: 0;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    // padding: 0 10px 0 0;
    margin-right: 10px;
    height: 450px;

    :focus-within {
      height: 600px;
    }
    align-self: center;

    // > div {
    //   border: 1px solid #eee;
    //   border-radius: 4px
    // }
  }

  @media ${tabletQuery} {
    width: 50%;
    float: left;
  }
`;

const ChatWrapper = styled.div`
  height: auto;
  width: 100%;
  flex: 1 0 auto;
  min-width: 320px;
`;

const VideoWrapper = styled.div`
  width: 100%;
  flex: 0 1 auto;
  align-items: center;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  @media (max-width: ${desktop - 1}px) and (orientation: portrait) {
    > * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @media (max-width: ${desktop - 1}px) and (orientation: landscape) {
    padding-bottom: 0;
    height: auto;
  }

  @media ${tabletQuery} {
    // padding-bottom: 0;
    // height: auto;
    position: relative;
  }
  @media ${desktopQuery} {
    //   position: relative;
    margin: 10px;
    padding-bottom: 0;
    height: auto;
    position: relative;
    flex: 1 1 auto;

    > * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const NTB = styled.img`
  height: 28px;
  margin: 0 5px;
`;

const DonationForm = styled.div`
  //  height: 450px;
  max-height: 100%;
  width: 100%;
  flex: 0 0 auto;
  // height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const chatVariants = {
  mobile: {
    width: "100%",
    display: "flex",
    height: "auto",
    overflow: "hidden",
    flex: "1 1 auto",
  },
  tablet: {
    width: "50%",
    display: "flex",
    height: "400px",
    overflow: "hidden",
    flex: "1 1 auto",
  },
  desktop: {
    width: 320,
    height: "auto",
    display: "flex",
    flex: "1 0 auto",
  },
  "mobile-closed": {
    display: "none",
  },
  "desktop-closed": {
    width: 0,
  },
};

const BasicLayout = (props) => {
  const { config } = props;
  const [width, height] = useWindowSize();
  const isMobile = window.matchMedia(`(max-width: ${desktop - 1}px)`).matches;
  const isDesktop = window.matchMedia(desktopQuery).matches;
  const isTablet = window.matchMedia(tabletQuery).matches;
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const [activeNav, setActiveNav] = useState(
    (isDesktop && "chat") ||
      (!!config.showDonationForm && "donate") ||
      (!!config.showChat && "chat") ||
      ""
  );
  const [showMobileFooter, setShowMobileFooter] = useState(false);
  // console.log({ isMobile, isTablet, isDesktop, isPortrait });

  useEffect(() => {
    window.actBlueConfig = {
      styleSheetHref: "/actblue.css",
      onContribute: (contribution) => {
        // tracking code goes here
        // console.log("contribution", contribution);
      },
    };
  });

  return (
    <OuterFrame style={{ height: window.innerHeight }}>
      {(isTablet || isDesktop) && (
        <Header className="header">
          <a
            href="https://www.berniesanders.com/"
            style={{ width: 100, height: 31, margin: 8, display: "block" }}
          >
            <img src="https://cms-assets.berniesanders.com/static/img/bernie-logo.svg" />
          </a>
          {config.showChat && isDesktop && (
            <Toggle
              onClick={(e) =>
                setActiveNav(activeNav === "chat" ? "donate" : "chat")
              }
            >
              <Icon
                color="white"
                icon={activeNav === "chat" ? "times" : "comments"}
              />
            </Toggle>
          )}
        </Header>
      )}
      {config.headline && isDesktop && (
        <Banner className="banner">
          <p style={{ textAlign: "center", flex: "1 1 auto" }}>
            {config.headline}
          </p>
        </Banner>
      )}
      <Stage className="stage">
        {config.videoURL && (
          <VideoWrapper className="videoWrapper">
            <HoverVideo url={config.videoURL} />
          </VideoWrapper>
        )}
        <Content>
          <Nav className="nav">
            <a
              href="https://www.berniesanders.com/"
              style={{
                flex: "0 0 auto",
                width: 90,
                height: 40,
                display: "block",
                margin: "0 20px",
              }}
            >
              <img
                src="https://cms-assets.berniesanders.com/static/img/bernie-logo.svg"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </a>
            {config.showDonationForm && (
              <NavButton
                active={activeNav === "donate"}
                onClick={() => setActiveNav("donate")}
              >
                <span>Donate</span>
              </NavButton>
            )}
            {config.showChat && (
              <NavButton
                active={activeNav === "chat"}
                onClick={() => setActiveNav("chat")}
              >
                <span>Chat</span>
              </NavButton>
            )}
            {/* <NavButton active={activeNav === 'sign'} onClick={() => setActiveNav('sign')}>
                <span>Sign Up</span>
              </NavButton> */}
          </Nav>
          {config.showDonationForm &&
            config.donationFormType === "actblueEmbed" && (
              <DonationFrame
                className="donationFrame"
                active={activeNav === "donate" || isTablet}
              >
                <DonationForm
                  className="donationForm"
                  data-ab-form
                  data-ab-amounts={
                    config.donationAmounts ||
                    "270,2700,5000,10000,27000,50000,100000"
                  }
                  data-ab-token={config.actblueToken}
                >
                  <a href="https://secure.actblue.com/donate/livestream_embed">
                    Donate
                  </a>
                </DonationForm>
              </DonationFrame>
            )}
          {config.showDonationForm &&
            config.donationFormType === "actblueLinks" && (
              <DonationFrame
                className="donationFrame"
                active={activeNav === "donate" || isTablet}
              >
                <div>
                  <p style={{ textAlign: "center" }}>
                    {config.donationCaption
                      ? config.donationCaption
                      : "If you've saved your payment information with ActBlue Express, your donation will go through immediately"}
                  </p>
                  {(config.donationAmounts || "10,27,50,100,270,500,1000")
                    .split(",")
                    .map((amt) => {
                      return (
                        <DonateButton
                          key={`donate_${amt}`}
                          href={`https://secure.actblue.com/donate/${config.actblueFormId}?express_lane=true&amount=${amt}&refcode=donatelive_${amt}`}
                          target="_blank"
                        >
                          Express Donate: ${amt}
                        </DonateButton>
                      );
                    })}
                  <DonateButton
                    key={"donate_other"}
                    href={`https://secure.actblue.com/donate/${config.actblueFormId}?express_lane_other=true&refcode=donatelive_other`}
                    target="_blank"
                  >
                    Or, donate another amount
                  </DonateButton>
                </div>
              </DonationFrame>
            )}
          <AnimatePresence>
            {config.showChat && config.chatPlatform && config.chatId && (
              <motion.div
                className="motionDiv"
                initial={
                  (isTablet && "tablet") || isDesktop ? "desktop" : "closed"
                }
                animate={
                  (isTablet && "tablet") ||
                  (isDesktop && activeNav === "chat" && "desktop") ||
                  (isDesktop && activeNav != "chat" && "desktop-closed") ||
                  (activeNav === "chat" && "mobile") ||
                  (activeNav !== "chat" && "mobile-closed")
                }
                variants={chatVariants}
                exit={
                  (isTablet && { width: "50%" }) ||
                  (isDesktop && activeNav !== "chat" && { width: 0 }) || {
                    width: "100%",
                  }
                }
                onUpdate={() => {
                  window.dispatchEvent(new Event("resize"));
                }}
              >
                <ChatWrapper className="chatWrapper">
                  <HoverChat
                    platform={config.chatPlatform}
                    id={config.chatId}
                  />
                </ChatWrapper>
              </motion.div>
            )}
          </AnimatePresence>
        </Content>
      </Stage>
      <Banner style={{ background: "#19465e" }}>
        {isMobile && !showMobileFooter ? (
          <div>
            <p
              style={{
                textTransform: "uppercase",
                border: "1px solid #fff",
                padding: 4,
                display: "inline-block",
                float: "left",
              }}
            >
              Paid for by Friends of Bernie Sanders
            </p>
            <Button onClick={(e) => setShowMobileFooter(!showMobileFooter)}>
              More <FontAwesomeIcon icon="caret-up" />
            </Button>
          </div>
        ) : (
          <Fragment>
            <div
              style={{
                display: isDesktop ? "flex" : "block",
                flex: "0 0 auto",
              }}
            >
              {isMobile && (
                <Button onClick={(e) => setShowMobileFooter(!showMobileFooter)}>
                  Hide <FontAwesomeIcon icon="caret-down" />
                </Button>
              )}
              <p
                style={{
                  textTransform: "uppercase",
                  border: "1px solid #fff",
                  padding: 4,
                  display: "inline-block",
                  flex: "0 0 auto",
                  lineHeight: "20px",
                }}
              >
                Paid for by Friends of Bernie Sanders
              </p>
              <p>
                <NTB src={ntb} />
              </p>
            </div>
            <div
              style={{
                display: isDesktop ? "flex" : "block",
                alignItems: "center",
              }}
            >
              <div style={{}}>
                <p style={{ textAlign: isDesktop ? "right" : "center" }}>
                  PO Box 391 Burlington, VT 05402
                </p>
                <p>
                  <a
                    href="https://berniesanders.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  <a
                    href="https://boards.greenhouse.io/bernie2020"
                    target="_blank"
                  >
                    Jobs
                  </a>{" "}
                  <a
                    href="https://berniesanders.com/commitment-to-diversity-equity-and-inclusion/"
                    target="_blank"
                  >
                    Commitment to Diversity, Equity and Inclusion
                  </a>{" "}
                  <a href="https://berniesanders.com/press/" target="_blank">
                    Press
                  </a>
                </p>
              </div>
              <div>
                <p
                  style={{
                    textAlign: isDesktop ? "right" : "center",
                    margin: "10px 20px",
                  }}
                >
                  <a
                    href="https://www.hovercast.com"
                    target="_blank"
                    style={{ display: "block" }}
                  >
                    <img
                      style={{ display: "block", margin: "0 auto" }}
                      src="/hovercast-bug.svg"
                      height={22}
                    />
                  </a>
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </Banner>
    </OuterFrame>
  );
};

const Layout = (props) => {
  let { pageSlug } = useParams();

  const [hasLoaded, setHasLoaded] = useState(false);

  const pagesCollection = firebase.firestore().collection("pages");

  const query = props.root
    ? pagesCollection.where("hostname", "==", window.location.hostname)
    : pagesCollection.where("pageSlug", "==", pageSlug);

  const [pages, pagesLoading, pagesError] = useCollectionData(query);
  const [portals, setPortals] = useState([]);

  useEffect(() => {
    // console.log(
    //   "Yo",
    //   document.querySelectorAll("hovervideo"),
    //   document.querySelectorAll("div")
    // );
    !hasLoaded &&
      document.querySelectorAll("hovervideo").forEach((el) => {
        setHasLoaded(true);
        let attrs = ["url"];
        // console.log("el + portals", portals, el);
        ReactDOM.render(
          <HoverVideo
            {...attrs.reduce((acc, key) => {
              acc[key] = el.getAttribute(key);
              return acc;
            }, {})}
          />,
          el
        );
      });
  });

  useEffect(() => {
    setHasLoaded(false);
  }, [pages]);

  if (pagesError) return <div>{pagesError}</div>;
  if (pagesLoading) return <div>&nbsp;</div>;
  if (!pages || pages.length === 0) return <div />;

  let config = pages[0];

  // console.log(config);
  // const config = {
  //   videoURL: 'https://www.twitch.tv/bernie_sanders',
  //   chatPlatform: 'twitch',
  //   chatId: 'bernie_sanders'
  // }
  let output = <BasicLayout config={config} />;

  if (config.layout === "custom") {
    let customOutput = DOMPurify.sanitize(config.customLayout, {
      ALLOWED_TAGS: ["div", "span", "img", "p", "hovervideo"],
      ALLOWED_ATTR: ["url", "class", "style"],
    });

    output = <div dangerouslySetInnerHTML={{ __html: customOutput }} />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>{config.pageTitle || "Live"}</title>
        <link rel="icon" type="image/png" href={config.pageIcon} />
      </Helmet>
      {output}
      {portals}
    </Fragment>
  );
};

export default Layout;
