import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import { useDebounce, useDebounceCallback } from "@react-hook/debounce";

const VideoWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  justify-content: center;
  align-items: center;
`;

const HoverVideo = props => {
  const wrapperEl = useRef(null);
  const [height, setHeight] = useState("100%");
  const [width, setWidth] = useState("100%");

  // console.log("PARENT", wrapperEl, wrapperEl.current,);

  useEffect(() => {
    // console.log(
    //   "PARENT",
    //   wrapperEl,
    //   wrapperEl.current,
    //   wrapperEl.current.parentElement.clientHeight,
    //   wrapperEl.current.parentElement.clientWidth
    // );

    const parentWidth = wrapperEl.current.parentElement.clientWidth;
    const parentHeight = wrapperEl.current.parentElement.clientHeight;
    let newHeight, newWidth;
    if (parentWidth / parentHeight > 16 / 9) {
      newHeight = parentHeight;
      newWidth = (parentHeight * 16) / 9;
    } else {
      newWidth = parentWidth;
      newHeight = (parentWidth * 9) / 16;
    }
    setHeight(newHeight);
    setWidth(newWidth);
    // console.log({ newHeight, newWidth });
  });
  // setTimeout(() => {
  // }, 1000);
  return (
    <VideoWrapper ref={wrapperEl}>
      <motion.div
        style={{ position: "absolute" }}
        animate={{ width, height }}
        transition={{ duration: 0.1 }}
      >
        <ReactPlayer
          width={"100%"}
          height={"100%"}
          url={props.url}
          playsinline
          muted
          playing={true}
        />
      </motion.div>
    </VideoWrapper>
  );
};

export default HoverVideo;
