import React from "react";
import styled from "styled-components";

const ChatWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 200px;
`;

const ChatIframe = styled.iframe`
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
`;

const buildPlatformChat = (platform, config) => {
  if (platform === "twitch") {
    return `https://www.twitch.tv/embed/${config.id}/chat?parent=${window.location.hostname}`;
  }
  if (platform === "youtube") {
    return `https://www.youtube.com/live_chat?v=${config.id}&embed_domain=${window.location.hostname}`;
  }
};
const CHAT_PLATFORMS = ["twitch", "youtube"];
const HoverChat = (props) => {
  const iframeSrc =
    props.url ||
    (CHAT_PLATFORMS.includes(props.platform) &&
      buildPlatformChat(props.platform, { id: props.id }));
  return (
    <ChatWrapper>
      <ChatIframe src={iframeSrc} />
    </ChatWrapper>
  );
};

export default HoverChat;
