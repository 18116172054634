import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const config = {
  apiKey: 'AIzaSyDTz-sR5qcP2QdWduA0ZBa7VUaa2NVjRuQ',
  authDomain: 'hovercast-bernie.firebaseapp.com',
  databaseURL: 'https://hovercast-bernie.firebaseio.com',
  projectId: 'hovercast-bernie',
  storageBucket: 'hovercast-bernie.appspot.com',
  messagingSenderId: '505651661456',
  appId: '1:505651661456:web:b534b394166bc9dd586615',
  measurementId: 'G-5H1M6R8C5H'
}

export const FIREBASE_ERRORS = {
  'auth/user-not-found': 'Email not found.',
  'auth/wrong-password': 'Password incorrect, please try again.'
}

// const config = {
//   apiKey: process.env.REACT_APP_FB_API_KEY,
//   authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
//   projectId: process.env.REACT_APP_FB_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID
// }

firebase.initializeApp(config)

export default firebase
